const isDev = (process.env.NODE_ENV === 'development');

const host = () => {
	if ( isDev ) {
		// return 'http://192.168.0.161:3000'; // Las Vegas 6293
		return 'http://192.168.0.54:3000'; // Las Vegas 8176
		// return 'http://192.168.1.2:3000'; // Lucknow
	}
	else {
		return 'https://9vajb75uqa.execute-api.us-east-2.amazonaws.com/latest';
	}
};

const getHeaders = async () => {
	let headers = {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	};

	// console.log('headers',headers);
	return headers;
};

const apiVersion = () => {
	return 'v1';
};

const sendRequest = async (url, jsonBody) => {
	return fetch(`${host()}/${apiVersion()}${url}`, {
		method: 'POST',
		headers: await getHeaders(),
		body: JSON.stringify(jsonBody),
		mode: 'cors',
	})
	.then(response => {
		// console.log('resp bef', JSON.stringify(response))
		if ( response.ok ) {
			return response.json();
		}
		else {
			throw response.statusText;
		}
	})
	.catch(error => {
		console.log('----------req error: ' + url);
		console.log(JSON.stringify(error));
		throw error;
	});
};

export {
	isDev,

	sendRequest,
};