import React from 'react';
import styles from './Privacy.module.css';
import { Link } from 'react-router-dom';

export default class Privacy extends React.Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className={styles.rootView}>
                {/* <div className={styles.logoView}>
                    <Link to="/">
                    </Link>
                </div> */}

                <div className={styles.header}>
                    Privacy
                </div>
                <div className={styles.infoBox}>
                    "Vibe Game", "us", "we", "our", "app", "KVK LLC" and any other variations mean Vibe Game.
                    <br /><br />
                    Your privacy is very important to us, and we respect the privacy of the users of our website and services and are committed to protecting it.
                    <br /><br />
                    When you sign-up on Vibe Game, we collect information, including your contacts in order to enable and provide certain features of the app. Prior to uploading user data, the app will ask user for their permission to access and upload of the data. Once you are registered with Vibe Game, you can upload pictures and videos (referred to as "content" from here) of yourself. When you upload content that contains your face, we upload them as is, and we do not extract any information from it, nor do we process any part of the content. We do not make use of your content's face data. The content you upload, which may or may not include your face, is only used to show in as-is format on the app. We may also, at times, collect our users' IP addresses and information regarding our users' use of our website and Services. We may also collect information related to the access times. Unless required by law, we will not share our users' personal information, Internet Protocol (IP) addresses and usage information, except with their consent or in aggregate or anonymous form, with third parties.
                    <br /><br />
                    In order to offer and provide optimized and personalized services, we use cookies to store and sometimes track information about our users.
                    <br /><br />
                    Vibe Game reserves the right to modify all or some of this Privacy Policy at any time without notice. We will use information only in accordance with the Privacy Policy under which the information was collected.
                    <br /><br />
                    If you have any questions about this Privacy Policy, the practices of this website or the Add-ons or your dealings with this Vibe Game, feel free to contact us.
                </div>
                <div className={styles.copyright}>© KVK LLC. 2024</div>
            </div>
        );
    };
};