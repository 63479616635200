import React from 'react';
import './App.css';
import {  BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';
import Home from './screens/Home/Home';
import Terms from './screens/Terms/Terms';
import Privacy from './screens/Privacy/Privacy';
import Feedback from './screens/Feedback/Feedback';
import Quiz from './screens/Quiz/Quiz';

export default class App extends React.Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route exact path="/terms" component={Terms} />
                    <Route exact path="/privacy" component={Privacy} />
                    <Route exact path="/quiz/:nickname" component={Quiz} />

                    <Route path="/feedback/359847fb-7e05-466d-9791-ec32b1b7c10a" component={Feedback} />
                    <Route path="*" component={Home} />
                </Switch>
            </Router>
        );
    };
};