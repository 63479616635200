import React from 'react';
import styles from './Terms.module.css';
import { Link } from 'react-router-dom';

export default class Terms extends React.Component {

    constructor(props) {
        super(props);
    };

    render() {
        return (
            <div className={styles.rootView}>
                {/* <div className={styles.logoView}>
                    <Link to="/">
                    </Link>
                </div> */}

                <div className={styles.header}>
                    Terms
                </div>
                <div className={styles.infoBox}>
                    "Vibe Game", "us", "we", "our", "app", "KVK LLC" and any other variations mean Vibe Game.
                    <br /><br />
                    These Terms of Service ("Terms") govern your access to and use of the services and Vibe Game's websites (the "Services"), apps, and any information, text, graphics, photos or other materials uploaded, downloaded or appearing on the Services (collectively referred to as "Content"). Your access to and use of the Services is conditioned on your acceptance of and compliance with these Terms. By accessing or using the Services you agree to be bound by these Terms.
                    <br /><br />
                    Vibe Game reserves the right to change these Terms at any time.
                    <br /><br />
                    <div className={styles.itemHeader}>Usage License</div>
                    Vibe Game grants you a limited license to access and use Vibe Game in accordance with these Terms. Vibe Game reserves the right to terminate your license to use Vibe Game at any time and for any reason or to charge for commercial usage in the future.
                    <br /><br />
                    <div className={styles.itemHeader}>Intellectual Property (Trademarks &amp; Copyrights)</div>
                    Vibe Game, the Vibe Game logo and the Vibe Game design are the trademarks or trade dress of Vibe Game, and may not be used without express written permission from Vibe Game, other than for attribution. All other trademarks not owned by Vibe Game that appear on Vibe Game are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by Vibe Game. Vibe Game-originated content included on the Site, such as text, graphics, logos, data compilations, software and the compilation of all content on the Site, is the property of Vibe Game and its licensers and protected by United States and international copyright laws. Except as set out in these Terms, no reproduction of any Vibe Game-originated content is permitted without written permission from Vibe Game.
                    <br /><br />
                    Any use or reproduction of user-posted content must comply with the terms of the respective license(s) and must include a label indicating such license.
                    <br /><br />
                    <div className={styles.itemHeader}>User Responsibility</div>
                    If you are under age 13, please do not attempt to register for Vibe Game or provide any personal information about yourself to us. If we learn that we have collected personal information from a child under age 13, we will delete that information as quickly as possible. If you believe that we might have any information from a child under age 13, please contact us.
                    <br /><br />
                    You are solely responsible for your use of Vibe Game. Because Vibe Game merely serves as a repository of information, user-posted content does not represent the advice, views, opinions or beliefs of Vibe Game, and Vibe Game makes no claim of accuracy of any user-posted material. Vibe Game allows for the posting of links and excerpts to third-party websites. The linked websites' content, business practices and privacy policies are not under the control of Vibe Game, and Vibe Game is not responsible for the content of any linked website or excerpt or any link contained in a linked website or excerpt. The inclusion of a link on Vibe Game does not imply any endorsement by or any affiliation with Vibe Game. In accessing Vibe Game or following links to third-party websites you may be exposed to content that you consider offensive or inappropriate. You agree that your only recourse is to stop using Vibe Game. You understand that the creation of bookmarks and excerpts in Vibe Game for purposes of specifically boosting exposure of a site in Vibe Game and/or search engines is a breach of these Terms, that Vibe Game views such entries as spam and that Vibe Game reserves the right to take appropriate action to restrict such entries up to and including termination of your access to Vibe Game.
                    <br /><br />
                    Do not use our Services to engage in illegal activities or to promote activities that are dangerous and illegal, such as the sale of illegal drugs or human trafficking.
                    <br /><br />
                    Do not transmit viruses, malware, or any other malicious or destructive code. Do not distribute content that harms or interferes with the operation of the networks, servers, or other infrastructure of Vibe Game or others. Do not use our Services for phishing scams.
                    <br /><br />
                    Our products are platforms for free expression. But we don't support content that promotes or condones violence against individuals or groups based on race or ethnic origin, religion, disability, gender, age, nationality, veteran status, or sexual orientation/gender identity, or whose primary purpose is inciting hatred on the basis of these core characteristics.
                    <br /><br />
                    Do not engage in harassing, bullying, or threatening behavior, and do not incite others to engage in these activities. Anyone using our Services to single someone out for malicious abuse, to threaten someone with serious harm, to sexualize a person in an unwanted way, or to harass in other ways may have the offending content removed or be permanently banned from using Vibe Game.
                    <br /><br />
                    Do not distribute other people’s personal and confidential information, such as credit card numbers, confidential national ID numbers, or account passwords, without their permission. Do not post or distribute images or videos of minors without the necessary consent from their legal representatives.
                    <br /><br />
                    Do not upload or share content that exploits or abuses children. This includes all child sexual abuse imagery (even cartoon images) and all content that presents children in a sexual manner. We will remove such content and take appropriate action, which may include disabling accounts and reporting to the National Center for Missing & Exploited Children (NCMEC) and law enforcement. Note that this policy may additionally apply to any content uploaded or transmitted through our Services.
                    <br /><br />
                    Do not spam, including by sending unwanted promotional or commercial content, or unwanted or mass solicitation.
                    <br /><br />
                    Do not distribute sexually explicit or pornographic material. Do not drive traffic to commercial pornography sites.
                    <br /><br />
                    It's not okay to post violent or gory content that's primarily intended to be shocking, sensational, or gratuitous.
                    <br /><br />
                    Do not use our Services to mislead or confuse users by pretending to be someone else or pretending to represent an organization you do not represent.
                    <br /><br />
                    Do not access another user’s account without their permission.
                    <br /><br />
                    We do not permit terrorist organizations to use Vibe Game for any purpose, including recruitment. Vibe Game also strictly prohibits content related to terrorism, such as content that promotes terrorist acts, incites violence, or celebrates terrorist attacks.
                    <br /><br />
                    Objectionable content and abusive users will not be tolerated on this platform, and such content and users will be removed from this platform.
                    <br /><br />
                    <div className={styles.itemHeader}>User-Posted Content</div>
                    By posting content, you are granting permission to Vibe Game and others to access and use it in connection with Vibe Game and otherwise in connection with its affiliates' businesses. Your use of a license in connection with your content does not affect Vibe Game's right to access and use it in connection with Vibe Game or otherwise in connection with its affiliates' businesses.
                    <br /><br />
                    Vibe Game does not provide legal services, and therefore, providing you with the ability to attach a license agreement to your compilation of links does not create an attorney-client relationship. The license agreements and all related information are provided on an "as is" basis. Vibe Game makes no warranties whatsoever regarding the license agreements and the information provided, and disclaims all liability for damages, including without limitation, any general, special, incidental, indirect or consequential damages, resulting from their use.
                    <br /><br />
                    Vibe Game is explicitly not responsible for the manner or circumstances by which third parties access or use public content and is under no obligation to disable or otherwise restrict this access. Vibe Game provides you with the ability to retrieve your posted content and your personal information from Vibe Game. This ability does not extend to copies that others may have made or to copies that Vibe Game may have made for backup purposes.
                    <br /><br />
                    <div className={styles.itemHeader}>No Warranty and Limitation of Liability</div>
                    Vibe Game PROVIDES THE SITE AND SERVICES "AS IS" AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY. Vibe Game SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, INFORMATION ACCURACY, INTEGRATION, or INTEROPERABILITY. Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimer may not apply to you.
                    <br /><br />
                    You understand and agree that you use Vibe Game at your own discretion and risk and that you will be solely responsible for any damages that arise from such use. UNDER NO CIRCUMSTANCES SHALL Vibe Game OR ITS AFFILIATES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY KIND, OR ANY OTHER DAMAGES WHATSOEVER (HOWEVER ARISING, INCLUDING BY NEGLIGENCE), INCLUDING WITHOUT LIMITATION, DAMAGES RELATED TO USE, MISUSE, RELIANCE ON, INABILITY TO USE AND INTERRUPTION, SUSPENSION, OR TERMINATION OF THE SITE OR SERVICES, DAMAGES INCURRED THROUGH ANY LINKS OR EXCERPTS PROVIDED ON THE SITE AND THE NONPERFORMANCE THEREOF AND DAMAGES RESULTING FROM LOSS OF USE, SALES, DATA, GOODWILL OR PROFITS, WHETHER OR NOT Vibe Game HAS BEEN ADVISED OF SUCH POSSIBILITY. YOUR ONLY RIGHT WITH RESPECT TO ANY DISSATISFACTION WITH Vibe Game SHALL BE TO TERMINATE USE OF Vibe Game. Some states do not allow the exclusion of liability for incidental or consequential damages, so the above exclusions may not apply to you. IN SUCH CASES, Vibe Game'S LIABILITY WILL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
                    <br /><br />
                    <div className={styles.itemHeader}>Other</div>
                    Vibe Game and you are independent entities, and nothing in the Terms, or via use of Vibe Game, will create any partnership, joint venture, agency, franchise, sales representative, or employment relationship between Vibe Game and you.
                    <br /><br />
                    These Terms supersede any previous agreement related to your use of Vibe Game and represent the entire agreement between Vibe Game and you with regard to your use of Vibe Game. These Terms are governed by the laws of the State of Nevada without reference to conflict of laws principles. If any provision of the Terms is adjudged to be illegal or unenforceable, the continuation in full force of the remainder of the Terms will not be prejudiced, and the illegal or unenforceable provision of the Terms shall be severed accordingly. Any notices may be sent to Vibe Game in English.
                    <br /><br />
                </div>
                <div className={styles.copyright}>© KVK LLC. 2024</div>
            </div>
        );
    };
};