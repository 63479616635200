import React from 'react';
import styles from './Feedback.module.css';
import * as utils from '../../utils/utils';

export default class Feedback extends React.Component {

    constructor(props) {
        super(props);

        this.screens = {
            Auth: 1,
            Support: 2,
        };

        this.monitorNewUserMessagesInterval = null;
        this.monitorNewUsersAskingForFeedbackInterval = null;
        this.isFetchingFreshMessages = false;

        this.state = {
            currentScreen: this.screens.Auth,

            headerKey1: '',
            headerKey2: '',

            users: [],
            selectedUserIndex: null,

            messages: [],

            message: '',

            infoBoxWidth: this.getWidth(),
        };
    };

    // interval for getting feedback users
    // interval for when user is selected and the messages



    async componentDidMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    };

    getWidth = () => {
        let width = window.innerWidth - 100;

        if ( width > 800 ) {
            width = 800;
        }

        return width;
    };

    handleWindowSizeChange = () => {
        this.setState({
            infoBoxWidth: this.getWidth(),
        });
    };

    resetPage = () => {
        this.setState({
            users: [],
            selectedUserIndex: null,

            messages: [],

            message: '',
        }, this.getUsersAskingFeedback);
    };

    keyAuth = () => {
        return {
            key1: this.state.headerKey1,
            key2: this.state.headerKey2,
        };
    };

    startApp = () => {
        this.getUsersAskingFeedback()
        .then(() => {
            this.setState({
                currentScreen: this.screens.Support,
            }, () => {
                this.beginMonitorForNewUserMessages();
                this.beginMonitorForNewUsersAskingForFeedback();
            });
        });
    };

    getUsersAskingFeedback = async () => {
        console.log('get new user feedbacks');

        return utils.sendRequest('/web/feedback/getUsersAskingFeedback',{
            ...this.keyAuth(),
        })
        .then(responseData => {
            const {
                users,
            } = responseData;

            this.setState({
                users,
            });
        })
        .catch(error => {
            console.log('error', error);
            throw error;
        });
    };

    beginMonitorForNewUserMessages = () => {
        this.monitorNewUserMessagesInterval = setInterval(
            this.getUserNewFeedbackMessages,
            5000
        );
    };

    stopMonitorForNewUserMessages = () => {
        clearInterval(this.monitorNewUserMessagesInterval);
    };

    beginMonitorForNewUsersAskingForFeedback = () => {
        this.monitorNewUsersAskingForFeedbackInterval = setInterval(
            this.getUsersAskingFeedback,
            60000
        );
    };

    stopMonitorForNewUsersAskingForFeedback = () => {
        clearInterval(this.monitorNewUsersAskingForFeedbackInterval);
    };

    getUserNewFeedbackMessages = () => {
        console.log('a', this.state.selectedUserIndex)
        console.log('b', this.isFetchingFreshMessages)
        if (
            this.state.selectedUserIndex == null ||
            this.isFetchingFreshMessages
        ) {
            return;
        }

        console.log('get new msgs');

        let {
            messages,
        } = this.state;

        this.isFetchingFreshMessages = true;

        let fromFeedbackID = 0;

        if ( messages.length > 0 ) {
            fromFeedbackID = messages[0].feedback_id;
        }

        utils.sendRequest('/web/feedback/getUserNewFeedbackMessages',{
            ...this.keyAuth(),
            userID: this.state.users[this.state.selectedUserIndex].user_id,
            fromFeedbackID,
        })
        .then(responseData => {
            if ( responseData.messages.length > 0 ) {
                console.log('newly added',responseData);

                const newMessages = responseData.messages;

                messages = [...newMessages, ...messages];

                this.setState({
                    messages,
                }, this.scrollToBottom);
            }
            this.isFetchingFreshMessages = false;
        })
        .catch(error => {
            console.log('new messages error', error);
            this.isFetchingFreshMessages = false;
        });
    };

    scrollToBottom = () => {
        if ( this.messageEndView ) {
            this.messageEndView.scrollIntoView();
        }  
    };

    renderAuthScreen = () => {
        return (
            <>
                <div>
                    <input
                        type='text'
                        placeholder='value 1'
                        value={this.state.headerKey1}
                        onChange={e => this.setState({ headerKey1: e.target.value })}
                    />
                </div>
                <div>
                    <input
                        type='text'
                        placeholder='value 2'
                        value={this.state.headerKey2}
                        onChange={e => this.setState({ headerKey2: e.target.value })}
                    />
                </div>
                <div>
                    <button onClick={this.startApp}>
                        Start
                    </button>
                </div>
            </>
        );
    };

    getUserFeedbackMessages = () => {
        utils.sendRequest('/web/feedback/getUserFeedbackMessages',{
            ...this.keyAuth(),
            userID: this.state.users[this.state.selectedUserIndex].user_id,
            page: 0,
        })
        .then(responseData => {
            const {
                messages,
            } = responseData;

            this.setState({
                messages,
            }, this.scrollToBottom);
        })
        .catch(error => {
            console.log('error', error);
        });
    };

    selectUser = (index) => {
        this.setState({
            selectedUserIndex: index,
        }, this.getUserFeedbackMessages);
    };

    confirmResolved = () => {
        if ( window.confirm('Is this really resolved?') ) {
            this.resolveUserFeedback();
        }
    };

    resolveUserFeedback = () => {
        utils.sendRequest('/web/feedback/resolveUserFeedback',{
            ...this.keyAuth(),
            userID: this.state.users[this.state.selectedUserIndex].user_id,
        })
        .then(responseData => {
            this.resetPage();
        })
        .catch(error => {
            console.log('error', error);
        });
    };

    postUserFeedbackMessage = () => {
        let {
            message
        } = this.state;

        message = message.trim();

        if ( message === '' ) {
            return;
        }

        utils.sendRequest('/web/feedback/postUserFeedbackMessage',{
            ...this.keyAuth(),
            userID: this.state.users[this.state.selectedUserIndex].user_id,
            message,
        })
        .then(responseData => {
            this.setState({
                message: '',
            }, this.getUserNewFeedbackMessages);
        })
        .catch(error => {
            console.log('error', error);
        });
    };

    renderSupportScreen = () => {
        return (
            <div className={styles.supportView}>
                <div className={styles.usersView}>
                    {this.state.users.map((user, index) => {
                        return (
                            <div
                                key={`user-${index}`}
                                className={index === this.state.selectedUserIndex ? styles.userItemSelected : styles.userItem}
                                onClick={() => this.selectUser(index)}
                            >
                                <div>ID: {user.user_id}</div>
                                <div>F: {user.first_name}</div>
                                <div>L: {user.last_name}</div>
                            </div>
                        )
                    })}
                </div>
                {
                    this.state.selectedUserIndex != null &&
                    <div className={styles.messagesView}>
                        <div className={styles.messagesList}>
                            {this.state.messages.reverse().map((message, index) => {
                                return (
                                    <div
                                        key={`message-${index}`}
                                        className={message.is_agent ? styles.messageItemAgent : styles.messageItemUser}
                                    >
                                        <div className={styles.userName}>{message.is_agent ? 'Kan' : 'User'}</div>
                                        <div>{message.message}</div>
                                    </div>
                                )
                            })}
                            <div ref={messageEndView => this.messageEndView = messageEndView} />
                        </div>
                        <div className={styles.messagesActionsView}>
                            <input
                                type='text'
                                className={styles.agentInputText}
                                value={this.state.message}
                                onChange={e => this.setState({ message: e.target.value })}
                                onKeyDown={e => {
                                    if ( e.key === 'Enter' ) {
                                        this.postUserFeedbackMessage()
                                    }
                                }}
                            />
                            <div className={styles.agentActionsView}>
                                <button
                                    onClick={this.postUserFeedbackMessage}
                                >
                                    Send
                                </button>
                                <button
                                    onClick={this.confirmResolved}
                                >
                                    Resolve
                                </button>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    };

    renderContent = () => {
        const {
            currentScreen,
        } = this.state;

        if ( currentScreen === this.screens.Auth ) {
            return this.renderAuthScreen();
        }
        else {
            return this.renderSupportScreen();
        }
    };

    render() {
        return (
            <div className={styles.rootView}>
                {this.renderContent()}
            </div>
        );
    };
};