import React from 'react';
import styles from './Quiz.module.css';
import { Link, withRouter } from 'react-router-dom';
import * as utils from '../../utils/utils';

export default class Quiz extends React.Component {

    constructor(props) {
        super(props);

        const isDev = (process.env.NODE_ENV === 'development');

        if ( isDev ) {
            this.scheme = 'vibegame-app-dev';
        }
        else {
            this.scheme = 'vibegame-app';
        }

        this.nickname = this.props.match.params.nickname || '';

        this.state = {
            isLoading: true,

            user: null,
        };
    };

    componentDidMount() {
        if ( this.nickname !== '' ) {
            utils.sendRequest('/web/quiz/getQuestionFromNickname',{
                nickname: this.nickname,
            })
            .then(responseData => {
                const {
                    user,
                } = responseData;
    
                this.setState({
                    user,
                    isLoading: false,
                });
            })
            .catch(error => {
                console.log('error', error);
            });
        }
    };
    

    render() {
        const {
            isLoading,
            user
        } = this.state;

        if ( isLoading ) {
            return (
                <div>Loading...</div>
            )
        }

        return (
            <div className={styles.rootView}>
                <img src={require('../../assets/animation.gif')} className={styles.background} />

                <div className={styles.content}>
                    <div className={styles.logoView}>
                        <img className={styles.vibeImage} src={require('../../assets/vibe.png')} />
                        <img className={styles.gameImage} src={require('../../assets/game.png')} />
                    </div>

                    <a href={`${this.scheme}://nickname/${this.nickname}`}>
                        <div className={styles.userView}>
                            <img className={styles.userPic} src={user.pic} />
                            <div className={styles.userInfoView}>
                                <div className={styles.userName}>{user.firstName} {user.lastName}</div>
                                <div className={styles.numNewQuizText}>{user.numQuizzes} New Quizzes</div>
                            </div>
                        </div>

                        <div className={styles.questionNumText}>
                            Question 1 of 8
                        </div>

                        <div className={styles.questionView}>
                            <div className={styles.questionText}>{user.question.q}</div>
                        </div>
                        <div className={styles.answersView}>
                            <div className={styles.answerItemView}>{user.question.options[0]}</div>
                            <div className={styles.answerItemView}>{user.question.options[1]}</div>

                            <div className={styles.answerItemView}>{user.question.options[2]}</div>
                            <div className={styles.answerItemView}>{user.question.options[3]}</div>
                        </div>
                    </a>

                    <a href="https://apps.apple.com/app/vibe-game/id6478194603">
                        <img src={require('../../assets/appstore.png')} className={styles.appstore} />
                    </a>

                    <div className={styles.footer}>
                        <a href='mailto:vibegameapp@gmail.com' className={styles.link}>Help</a>
                        <Link to="/terms" className={styles.link}>Terms</Link>
                        <Link to="/privacy" className={styles.link}>Privacy</Link>
                    </div>
                    <div className={styles.copyright}>© KVK LLC. 2024</div>
                </div>

            </div>
        );
    };
};